<template>
  <div>
    <v-container>
      <v-breadcrumbs
        :items="breadcrumbs"
        class="pa-0 pb-2"
        divider=">"
      ></v-breadcrumbs>
      <v-row align="center" class="mb-2">
        <v-col cols="auto" class="pr-12">
          <h1>Orders</h1>
        </v-col>
        <v-col class="pl-12"> </v-col>
      </v-row>
      <v-card>
        <v-toolbar flat dark dense color="accent">
          <v-toolbar-title>Orders</v-toolbar-title>
          <div class="spacer"></div>
        </v-toolbar>
        <v-data-table
          :headers="tableHeaders"
          :items="orders"
          class="mt-4"
          no-data-text="No Order found"
        >
          <template v-slot:item.actions="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  x-small
                  depressed
                  color="green lighten-4 green--text"
                  v-on="on"
                  class="mr-2"
                  :to="{
                    name: 'module-arnprior-orders-arnprior-orders-vouchers-individual',
                    params: { orderId: item.id },
                  }"
                >
                  <v-icon x-small>mdi-eye</v-icon>
                </v-btn>
              </template>
              <span>View</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-card>
    </v-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      breadcrumbs: [
        {
          text: "Orders",
          disabled: true,
        },
      ],
      searchTerm: "",
      tableHeaders: [
        { text: "Order ID", value: "id" },
        { text: "Customer", value: "customer.full_name" },
        { text: "Sub Total", value: "sub_total" },
        { text: "Total", value: "total" },
        { text: "Status", value: "status" },
        { text: "Actions", value: "actions", sortable: false, align: "right" },
      ],
    };
  },

  methods: {},

  computed: {
    orders() {
      return this.$store.getters["arnprior/ordersStore/all"];
    },
  },
};
</script>
